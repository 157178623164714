* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #F8F8F8;
  font-family: QuickSand, Open Sans, Helvetica, Arial, 'sans-serif';
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}


